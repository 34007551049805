import { ImageTextCard } from "@/components";
import Styles from "./ImageCard.module.css";
import useColorPicker from "@/Utility/useColorPicker";

const ImageCard = ({
	image,
	heading,
	description,
	cta_link,
	cta_label,
	image_right,
	padding_top,
	padding_bottom,
	background_color,
	full_width,
	small_image,
	remove_max_height,
}) => {
	const background = useColorPicker(background_color);
	return (
		<div
			className={Styles.wrapper}
			data-padding-top={padding_top}
			data-padding-bottom={padding_bottom}
			style={{ backgroundColor: background }}
		>
			<ImageTextCard
				image={image}
				heading={heading}
				description={description}
				ctaLink={cta_link}
				ctaLabel={cta_label}
				imageRight={image_right}
				fullWidth={full_width}
				imageSmall={small_image}
				removeMaxHeight={remove_max_height}
			/>
		</div>
	);
};

export default ImageCard;
