import { Button, VideoPlayer } from "@/components";
import { PrismicRichText } from "@prismicio/react";

import Styles from "./Video.module.css";

const Video = ({
	video,
	heading,
	description,
	cta_label,
	cta_link,
	video_right,
	padding_top,
	padding_bottom,
}) => {
	return (
		<div
			className={Styles.wrapper}
			data-padding-top={padding_top}
			data-padding-bottom={padding_bottom}
		>
			<div
				className={Styles.container}
				data-contain="true"
				data-reverse={video_right}
			>
				<div className={Styles.col}>
					<VideoPlayer
						videoType="native"
						videoUrl={video?.url}
						theme={{
							primary: "var(--red)",
							secondary: "var(--dark-grey)",
							icon: "var(--white)",
						}}
						autoPlay={false}
					/>
				</div>
				<div className={Styles.col}>
					<h3 className={Styles.heading}>{heading}</h3>
					<PrismicRichText field={description} />
					<Button link={cta_link} label={cta_label} />
				</div>
			</div>
		</div>
	);
};

export default Video;
