const useColorPicker = (color) => {
	switch (color) {
		case "White":
			return "var(--white)";
		case "Red":
			return "var(--red)";
		case "Light Grey":
			return "var(--light-grey)";
		default:
			return "var(--white)";
	}
};

export default useColorPicker;

/* Json Options

"White", 
"Light Grey", 
"Red", 


*/
