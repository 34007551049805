import Video from "./Video";
import ImageCard from "./ImageCard";

/**
 * @typedef {import("@prismicio/client").Content.TwoColumnsSlice} TwoColumnsSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<TwoColumnsSlice>} TwoColumnsProps
 * @param {TwoColumnsProps}
 */
const TwoColumns = ({ slice: { primary, variation } }) => {
	if (variation == "default") return <ImageCard {...primary} />;
	if (variation == "video") return <Video {...primary} />;
};

export default TwoColumns;
